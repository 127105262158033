import React, { Component } from 'react';
import styled from 'styled-components';
import { graphql, Link } from 'gatsby';
import { withSize } from 'react-sizeme';

import Layout from '../components/layout';
import Hero from '../components/hero';
import SEO from '../components/seo';

import heroImageDesktop from '../images/hero-leadership-desktop.png';

import {
    JamesHaslam,
    JimmyHaslam,
    KristinSeabrook,
    MikeRodgers,
    AdamWright,
    JoeLillo,
    BradAnderson,
    MarilynFontaine,
    BradJenkins,
    DavidHughes,
    BrianFerguson,
    AdrienneIngoldt,
    AllisonCornish,
    JamesChiu,
    AndyLupo,
    MattPrice
} from '../images/leadership';

const BioTemplateStyled = styled.div`
    background: linear-gradient(
        180deg,
        rgba(228, 226, 219, 1) 0%,
        rgba(255, 255, 255, 1) 100%
    );
    display: flex;
    flex-direction: column;
    align-items: center;

    .bio {
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 90%;
        max-width: 1108px;
        padding-top: 48px;
    }

    h1 {
        font-size: 40px;
        font-style: italic;
        letter-spacing: 3px;
        text-transform: uppercase;
    }

    span {
        display: block;
        margin: 20px 0 36px;
        font: 400 20px roboto, sans-serif;
        letter-spacing: 2.5px;
        text-transform: uppercase;
        color: #6c7279;
    }
    p {
        margin-bottom: 48px;
        font: 400 16px roboto, sans-serif;
        line-height: 32px;
        color: #6c7279;
    }

    figure {
        position: relative;
        float: right;
        margin: -158px 0 38px 38px;
        z-index: 99;

        .bio__pic {
            width: 516px;
            height: auto;
            max-width: 100%;
            border-radius: 20px;
            border: 2px solid #fff;
        }

        @media screen and (max-width: 768px) {
            margin: 0 0 38px 0;
        }
    }

    .bio__nav {
        border-top: 1px solid #d8d8d8;
        width: 90%;
        max-width: 1108px;

        ul {
            display: flex;
            justify-content: flex-end;
            margin: 40px 0 88px;

            li {
                list-style: none;
                margin-right: 40px;

                &:last-child {
                    margin-right: 0;
                }

                a,
                p {
                    color: #0b1822;
                    font: 700 14px purista-web, sans-serif;
                    letter-spacing: 2.19px;
                    text-transform: uppercase;
                    text-decoration: none;
                }

                p {
                    margin-top: 2px;
                    color: #e4e2db;
                }
            }
        }
    }

    @media screen and (max-width: 768px) {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
`;

class bioTemplate extends Component {
    getImage = path => {
        switch (path) {
            case 'james-haslam':
                return JamesHaslam;
            case 'jimmy-haslam':
                return JimmyHaslam;
            case 'kristin-seabrook':
                return KristinSeabrook;
            case 'mike-rodgers':
                return MikeRodgers;
            case 'adam-wright':
                return AdamWright;
            case 'joe-lillo':
                return JoeLillo;
            case 'brad-anderson':
                return BradAnderson;
            case 'brad-jenkins':
                return BradJenkins;
            case 'david-hughes':
                return DavidHughes;
            case 'brian-ferguson':
                return BrianFerguson;
        }
    };
    render() {
        let bio = this.props.data.allSitePage.edges[0].node.pageContext;
        let bioImage = this.getImage(bio.bioPath);

        return (
            <Layout path={this.props.path}>
                <BioTemplateStyled>
                    <SEO 
                        title={bio.titleTag ? bio.titleTag : "Pilot Company Home Page"}
                        description = {bio.metaDescription ? bio.metaDescription : "Pilot Company"}
                     />
                    <Hero
                        headerText="Pilot Company Leadership"
                        background={
                            this.props.size.width > 768
                                ? heroImageDesktop
                                : heroImageDesktop
                        }
                        showLogo={false}
                        isSubPage={true}
                    ></Hero>
                    <section className="bio">
                        <article>
                            <div>
                                <figure>
                                    <img
                                        className="bio__pic"
                                        src={bioImage}
                                        width="516"
                                        height="582"
                                        alt={`${bio.name} headshot`}
                                    />
                                </figure>
                                <h1>{bio.name}</h1>
                                <span>{bio.level && <>{bio.level}<br/></>}{bio.title}</span>
                                <div
                                    dangerouslySetInnerHTML={{
                                        __html: bio.bioCopy,
                                    }}
                                ></div>
                            </div>
                        </article>
                    </section>
                    <section className="bio__nav">
                        <ul>
                            <li>
                                <Link to="/leadership">Back To Leadership</Link>
                            </li>
                            <li>
                                {bio.prevLeaderLink ? (
                                    <Link
                                        className={
                                            bio.prevLeaderLink === ''
                                                ? 'disabled'
                                                : ''
                                        }
                                        to={`/leadership/${bio.prevLeaderLink}`}
                                    >
                                        {bio.prevLeader}
                                    </Link>
                                ) : (
                                    <p>Previous</p>
                                )}
                            </li>
                            <li>
                                {bio.nextLeader ? (
                                    <Link
                                        className={
                                            bio.nextLeader === ''
                                                ? 'disabled'
                                                : ''
                                        }
                                        to={`/leadership/${bio.nextLeaderLink}`}
                                    >
                                        {bio.nextLeader}
                                    </Link>
                                ) : (<p>Next</p>)}
                            </li>
                        </ul>
                    </section>
                </BioTemplateStyled>
            </Layout>
        );
    }
}

export const query = graphql`
    query($path: String!) {
        allSitePage(filter: { path: { eq: $path } }) {
            edges {
                node {
                    pageContext 
                }
            }
        }
    }
`;

export default withSize()(bioTemplate);
